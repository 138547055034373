import React from "react"
import styled from "styled-components"
import Cocktails from "../../images/cocktail-party.jpg"
import LeafBorder from "../../images/hand-painted-leaf-border-cut.png"
import Layout from "../../components/layout-canada"
import SEO from "../../components/seo"

const Picture = styled.div`
  background-image: url("${Cocktails}");
  width: 100%;
  height: 400px;
  background-position: 40%;
  margin-bottom: 42px;
  @media (max-width: 768px) {
    height: 200px;
    background-size: auto 200px;
    background-repeat: no-repeat;
  }
`

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 300px;
  margin-bottom: 32px;
  background-size: 300px 30px;
`

const H1 = styled.h1`
  margin-bottom: 6px;
`

const Subheader = styled.h3`
  font-family: "Benton Sans";
`

const H4 = styled.h4`
  font-family: "Benton Sans";
  margin-bottom: 42px;
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

const Tab = styled.span`
  padding: 0 15px;
`

class SaturdayPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Saturday | Sara & Tim" />
        <H1>SATURDAY, JUNE 11TH</H1>
        <LeafBorderPicture />
        <Picture></Picture>
        <Subheader>Wedding celebration!</Subheader>
        <H4>
          The Croxford Residence, Mission Ridge Road, Kelowna (exact address to
          be shared directly)
        </H4>
        <P>
          We missed you at our UK Wedding last year! To have a chance to
          properly celebrate with you all, we would like to invite you to a
          slightly less formal affair around Jan and Steve's.
        </P>
        <P>
          We will be in the garden, enjoying the beautiful early summer sunshine
          (weather permitting).
        </P>
        <P>
          Nibbles will be provided throughout the afternoon, and an evening meal
          later on, along with a range of alcoholic and non-alcoholic drinks.
        </P>
        <P>Dresscode is smart-casual / cocktail party attire.</P>
        <P>Children welcome.</P>
        <P>
          P.S. - there's a pool. If you fancy a dip, please bring your swimsuit!
        </P>
        <P>
          14:00<Tab></Tab>Arrive{" "}
        </P>
        <P>
          17:00<Tab></Tab>Evening buffet
        </P>
        <P>
          19:00<Tab></Tab>Cutting of the cake (well, we have to, right?)
        </P>
        <P>
          23:00<Tab></Tab>Bedtime
        </P>
      </Layout>
    )
  }
}

export default SaturdayPage
